import { ApplicationAccess } from '../constants/SubscriptionProducts';
import * as ReportApp from '../typescript/types';

export const getLicenseFromSessionInfo = (
    sessionInfo: ReportApp.OmniWebService.SessionInfo,
): ReportApp.SessionInfo => ({
    licenceTitle: hasValidSubscription(sessionInfo)
        ? isStarter(sessionInfo)
            ? 'Starter plan'
            : 'Professional plan'
        : 'Basic plan',
    hasPro: hasValidSubscription(sessionInfo),
    isTrialing: isTrial(sessionInfo),
});

export const hasValidSubscription = (
    sessionInfo: ReportApp.OmniWebService.SessionInfo,
) => {
    return isPro(sessionInfo) || isStarter(sessionInfo);
};

export const isPro = (sessionInfo: ReportApp.OmniWebService.SessionInfo) => {
    return activeSubscriptionsContainProduct(
        sessionInfo.activeSubscriptions,
        ApplicationAccess.TRACTIQ_SELF_STORAGE,
    );
};

export const isStarter = (
    sessionInfo: ReportApp.OmniWebService.SessionInfo,
) => {
    return (
        !isPro(sessionInfo) &&
        activeSubscriptionsContainProduct(
            sessionInfo.activeSubscriptions,
            ApplicationAccess.TRACTIQ_SELF_STORAGE_STARTER_TIER,
        )
    );
};

export const isTrial = (sessionInfo: ReportApp.OmniWebService.SessionInfo) => {
    return (
        sessionInfo.activeSubscriptions?.some((sub) => sub.type === 'Trial') ??
        false
    );
};

export const activeSubscriptionsContainProduct = (
    subscriptions: ReportApp.OmniWebService.Subscription[],
    product: ApplicationAccess,
) => {
    return subscriptions.some((subscription) => {
        return subscription.products
            .map((product) => product.productId)
            .includes(product);
    });
};
